import { combineReducers } from "redux";
import auth from "./auth";
import LoggedReducer from "./isLogged";
import changeState from "./dashboardState";
import courseState from "./courseState";
import teacherState from "./teacherState";
import certificate from "./certificate";
import certificationEmp from "./certificationEmp";
import license from "./license";
import licenseEmp from "./licenseEmp";
import courseList from "./courses";
import courseSetting from "./coursesetting";
import courseData from "./coursedata";
import coursecode from "./coursecode";
import courseidp from "./coursesidp";
import empregister from "./empregister";
import category from "./category";
import institution from "./institution";
import institutionProfile from "./institutionProfile";
import teachers from "./teachers";
import teacherData from "./teacherData";
import assessor from "./assessor";
import gapAndtarget from "./gap-target";
import position from "./position";
import positioncourse from "./positioncourse";
import department from "./department";
import section from "./section";
import affiliate from "./affiliate";
import affiliateOrganization from "./affiliate-organization";
import quiz from "./quiz";
import quizStatus from "./quizStatus";
import quizquestion from "./quizQuestion";
import skills from "./skill";
import skilldata from "./skilldata";
import skillGroup from "./skillGroup";
import skillType from "./skillType";
import skillLevel from "./skillLevel";
import skillappoint from "./skillMyAppoint";
import skillsetting from "./skillsetting";
import competencylist from "./competencylist";
import competencysetting from "./competencysetting";
import competencylevel from "./competencylevel";
import competencyEvaluate from "./competencyEvaluate";
import competencySuggest from "./competencySuggest";
import competencyEmployee from "./competencyEmployee";

import onJobTrainingList from "./onJobTrainingList";
import onJobTrainingStatus from "./onJobTrainingStatus";
import onJobTrainingData from "./onJobTrainingData";
import employees from "./employees";
import employeedata from "./employeedata";
import manager from "./manager";
import users from "./user";
import userProfile from "./user-profile";
import video from "./video";
import approveList from "./approve";
import dashboardData from "./dashboarddata";
import potential from "./potential";



const allReducers = combineReducers({
  Logged: LoggedReducer,
  changeState: changeState,
  courseState: courseState,
  teacherState: teacherState,
  auth,
  users,
  userProfile,
  certificate,
  certificationEmp,
  category,
  courseList,
  courseSetting,
  courseData,
  coursecode,
  courseidp,
  dashboardData,
  license,
  licenseEmp,
  empregister,
  institution,
  institutionProfile,
  teachers,
  teacherData,
  assessor,
  gapAndtarget,
  position,
  positioncourse,
  department,
  section,
  affiliate,
  affiliateOrganization,
  quiz,
  quizStatus,
  quizquestion,
  skills,
  skilldata,
  skillGroup,
  skillType,
  skillLevel,
  skillappoint,
  skillsetting,
  competencylist,
  competencysetting,
  competencylevel,
  competencyEvaluate,
  competencySuggest,
  competencyEmployee,
  onJobTrainingList,
  onJobTrainingStatus,
  onJobTrainingData,
  employees,
  employeedata,
  manager,
  video,
  approveList,
  potential,
});

export default allReducers;
